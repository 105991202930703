import {AnyAction} from 'redux'
import {PlacedOrderState} from '../types'
import {PLACE_ORDER, UPDATE_ORDER, CANCEL_ORDER, GET_ORDER} from '../actions/placed-order'
import {RESERVE_TICKETS} from '../actions/reservation'

const defaultState: PlacedOrderState = {
  order: undefined,
}

export const placedOrder = (state = defaultState, action: AnyAction): PlacedOrderState => {
  switch (action.type) {
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case GET_ORDER.SUCCESS:
      return {order: action.payload.order}
    case RESERVE_TICKETS.REQUEST:
    case CANCEL_ORDER:
      return defaultState
    default:
      return state
  }
}
